var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"outlined":""}},[_c('v-subheader',[_vm._v(" "+_vm._s(_vm.title))]),_c('div',{staticClass:"mx-3"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-chip-group',{staticClass:"mt-2",attrs:{"column":"","multiple":_vm.isSelectMultipleDaysEnabled,"active-class":"light-green lighten-3","mandatory":""},on:{"change":_vm.selectionOfDaysChanged},model:{value:(_vm.selectionOfDays),callback:function ($$v) {_vm.selectionOfDays=$$v},expression:"selectionOfDays"}},_vm._l((_vm.days),function(day){return _c('v-chip',{key:day},[_vm._v(" "+_vm._s(day)+" ")])}),1),_c('v-spacer'),_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.syncTime,"persistent":"","transition":"scale-transition","origin":"center center","width":"290px"},on:{"update:returnValue":function($event){_vm.syncTime=$event},"update:return-value":function($event){_vm.syncTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('chooseSyncTime'),"prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.syncTime),callback:function ($$v) {_vm.syncTime=$$v},expression:"syncTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[(_vm.modal)?_c('v-time-picker',{model:{value:(_vm.syncTime),callback:function ($$v) {_vm.syncTime=$$v},expression:"syncTime"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialog.save(_vm.syncTime);
              _vm.syncTimeChanged();}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1):_vm._e()],1),_c('v-spacer'),_c('v-sheet',{staticClass:"d-inline-flex",attrs:{"elevation":"0"}},[_c('v-checkbox',{class:(_vm.$vuetify.breakpoint.width < 1499 &&
              _vm.$vuetify.breakpoint.width > 1347) ||
            (_vm.$vuetify.breakpoint.width < 1243 &&
              _vm.$vuetify.breakpoint.width > 1091) ||
            _vm.$vuetify.breakpoint.width < 549
              ? 'mt-n1'
              : 'mt-5',attrs:{"disabled":!_vm.isEveryHourEnabled,"label":_vm.$t('everyHour')},on:{"change":_vm.everyHourChanged},model:{value:(_vm.everyHour),callback:function ($$v) {_vm.everyHour=$$v},expression:"everyHour"}}),_c('div',{staticClass:"mr-0 ml-2",class:(_vm.$vuetify.breakpoint.width < 1499 &&
              _vm.$vuetify.breakpoint.width > 1347) ||
            (_vm.$vuetify.breakpoint.width < 1243 &&
              _vm.$vuetify.breakpoint.width > 1091) ||
            _vm.$vuetify.breakpoint.width < 549
              ? 'mt-0'
              : 'mt-6'},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline")])]}}])},[(!_vm.isEveryHourEnabled)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("toolTips.everyHourNotPossible"))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("toolTips.everyHour"))+" ")])])],1)],1)],1)],1),_c('v-snackbar',{attrs:{"right":"","top":"","app":"","color":"success"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.configSaved = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t("buttons.close"))+" ")])]}}]),model:{value:(_vm.configSaved),callback:function ($$v) {_vm.configSaved=$$v},expression:"configSaved"}},[_vm._v(" "+_vm._s(this.title === this.$t('configObjectsSync') ? _vm.$t("notificationSnackbars.syncConfigurationObjects") : _vm.$t("notificationSnackbars.syncConfigurationTimeEntries"))+" "+_vm._s(_vm.connectionBoxId)+" "+_vm._s(_vm.$t("notificationSnackbars.savedUpdated"))+" ")]),_c('v-snackbar',{attrs:{"right":"","top":"","app":"","color":"error"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.configSavedUnsuccessful = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t("buttons.close"))+" ")])]}}]),model:{value:(_vm.configSavedUnsuccessful),callback:function ($$v) {_vm.configSavedUnsuccessful=$$v},expression:"configSavedUnsuccessful"}},[_vm._v(" "+_vm._s(this.title === this.$t('configObjectsSync') ? _vm.$t("notificationSnackbars.syncConfigurationObjects") : _vm.$t("notificationSnackbars.syncConfigurationTimeEntries"))+" "+_vm._s(_vm.connectionBoxId)+" "+_vm._s(_vm.$t("notificationSnackbars.notSuccessful"))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }