const WeekDay = {
  MONDAY: "weekDay.monday",
  TUESDAY: "weekDay.tuesday",
  WEDNESDAY: "weekDay.wednesday",
  THURSDAY: "weekDay.thursday",
  FRIDAY: "weekDay.friday",
  SATURDAY: "weekDay.saturday",
  SUNDAY: "weekDay.sunday",
};

export { WeekDay };
