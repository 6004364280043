<template>
  <v-card outlined>
    <v-subheader> {{ title }}</v-subheader>
    <div class="mx-3">
      <v-row no-gutters>
        <v-chip-group
            column
            :multiple="isSelectMultipleDaysEnabled"
            active-class="light-green lighten-3"
            mandatory
            v-model="selectionOfDays"
            class="mt-2"
            @change="selectionOfDaysChanged"
        >
          <v-chip v-for="day in days" :key="day">
            {{ day }}
          </v-chip>
        </v-chip-group>
        <v-spacer></v-spacer>
        <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="syncTime"
            persistent
            transition="scale-transition"
            origin="center center"
            width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="syncTime"
                :label="$t('chooseSyncTime')"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker v-if="modal" v-model="syncTime">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="
                $refs.dialog.save(syncTime);
                syncTimeChanged();
              "
            >
              {{ $t("ok") }}
            </v-btn>
          </v-time-picker>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-sheet elevation="0" class="d-inline-flex">
          <v-checkbox
              v-model="everyHour"
              :disabled="!isEveryHourEnabled"
              :label="$t('everyHour')"
              :class="
              ($vuetify.breakpoint.width < 1499 &&
                $vuetify.breakpoint.width > 1347) ||
              ($vuetify.breakpoint.width < 1243 &&
                $vuetify.breakpoint.width > 1091) ||
              $vuetify.breakpoint.width < 549
                ? 'mt-n1'
                : 'mt-5'
            "
              @change="everyHourChanged"
          ></v-checkbox>
          <div
              class="mr-0 ml-2"
              :class="
              ($vuetify.breakpoint.width < 1499 &&
                $vuetify.breakpoint.width > 1347) ||
              ($vuetify.breakpoint.width < 1243 &&
                $vuetify.breakpoint.width > 1091) ||
              $vuetify.breakpoint.width < 549
                ? 'mt-0'
                : 'mt-6'
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"> mdi-help-circle-outline</v-icon>
              </template>
              <span
                  v-if="!isEveryHourEnabled"
              >
                {{ $t("toolTips.everyHourNotPossible") }}
              </span>
              <span v-else>
                {{ $t("toolTips.everyHour") }}
              </span>
            </v-tooltip>
          </div>
        </v-sheet>
      </v-row>
    </div>
    <v-snackbar right top app color="success" v-model="configSaved">
      {{
        this.title === this.$t('configObjectsSync') ? $t("notificationSnackbars.syncConfigurationObjects") : $t("notificationSnackbars.syncConfigurationTimeEntries")
      }}
      {{ connectionBoxId }}
      {{ $t("notificationSnackbars.savedUpdated") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="configSaved = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar right top app color="error" v-model="configSavedUnsuccessful">
      {{
        this.title === this.$t('configObjectsSync') ? $t("notificationSnackbars.syncConfigurationObjects") : $t("notificationSnackbars.syncConfigurationTimeEntries")
      }}
      {{ connectionBoxId }}
      {{ $t("notificationSnackbars.notSuccessful") }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="configSavedUnsuccessful = false">
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import {WeekDay} from "@/enums/weekDay";
import {Constants} from "../../../constants/Constants";
import {isEveryHourEnabled, isSelectMultipleDaysEnabled} from "@timer2ticket/timer2ticket-client-vue-library"
import {EventBus} from "../../../EventBus";
import {patchConnection} from "../../../services/backendService";

export default {
  data() {
    return {
      isCommercialVersion: Constants.IS_COMMERCIAL_VERSION,
      isSelectMultipleDaysEnabled: false,
      isEveryHourEnabled: false,

      modal: false,
      days: [
        this.$t(WeekDay.MONDAY),
        this.$t(WeekDay.TUESDAY),
        this.$t(WeekDay.WEDNESDAY),
        this.$t(WeekDay.THURSDAY),
        this.$t(WeekDay.FRIDAY),
        this.$t(WeekDay.SATURDAY),
        this.$t(WeekDay.SUNDAY),
      ],
      selectionOfDays: [],
      syncTime: "",
      everyHour: false,

      debounceTimer: null,
      debounceDelay: 600, // ms
      lastProcessedElectionOfDays: [],
      lastProcessedSyncTime: "",
      lastProcessedEveryHour: false,

      configSaved: false,
      configSavedUnsuccessful: false,
    };
  },
  created() {
    this.isSelectMultipleDaysEnabled = !this.isCommercialVersion || isSelectMultipleDaysEnabled(this.$store.state.membership.name);
    this.isEveryHourEnabled = !this.isCommercialVersion || isEveryHourEnabled(this.$store.state.membership.name);

    this.fetchTimeChoser();
  },
  methods: {
    selectionOfDaysChanged() {
      this.isChanged();
    },
    syncTimeChanged() {
      this.isChanged();
    },
    everyHourChanged() {
      this.isChanged();
    },

    // Connection box methods
    getCurrentConnectionBox() {
      let connectionBox = null;
      for (let i = 0; i < this.$store.state.userConnectionBoxes.length; ++i) {
        if (
            this.connectionBoxId ===
            this.$store.state.userConnectionBoxes[i].connectionBoxId
        ) {
          connectionBox = this.$store.state.userConnectionBoxes[i];
          break;
        }
      }
      return connectionBox;
    },

    isChanged() {
      const timeObject = {
        selectionOfDays: this.getSelectionOfDaysArray(),
        syncTime: this.syncTime,
        everyHour: this.everyHour,
      };

      if (!this.connectionBoxId.toString().startsWith(this.$t("connectionBoxIdNew"))) {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(async () => {
          // noinspection EqualityComparisonWithCoercionJS
          if ((this.lastProcessedElectionOfDays != this.selectionOfDays
                  || this.lastProcessedSyncTime !== this.syncTime
                  || this.lastProcessedEveryHour !== this.everyHour)) {
            const response = await this.sendTimeObject();
            if (response) {
              timeObject.lastJobTime = this.title === this.$t('configObjectsSync') ? response.configSyncJobDefinition.lastJobTime : response.timeEntrySyncJobDefinition.lastJobTime;
              timeObject.schedule = this.title === this.$t('configObjectsSync') ? response.configSyncJobDefinition.schedule : response.timeEntrySyncJobDefinition.schedule;

              // this.dispatchCurrentConnectionBox(timeObject);
              this.lastProcessedElectionOfDays = this.selectionOfDays;
              this.lastProcessedSyncTime = this.syncTime;
              this.lastProcessedEveryHour = this.everyHour;
            } else {
              this.selectionOfDays = this.lastProcessedElectionOfDays;
              this.syncTime = this.lastProcessedSyncTime;
              this.everyHour = this.lastProcessedEveryHour;
            }
          }
        }, this.debounceDelay);
      } else {
        this.dispatchCurrentConnectionBox(timeObject);
        this.lastProcessedElectionOfDays = this.selectionOfDays;
        this.lastProcessedSyncTime = this.syncTime;
        this.lastProcessedEveryHour = this.everyHour;
      }
    },

    async sendTimeObject() {
      const bodyToSend = this.getObjectToSend();
      const currentConnectionBox = this.getCurrentConnectionBox();
      const response = await patchConnection(currentConnectionBox.databaseId, bodyToSend);
      if (!response) {
        this.configSavedUnsuccessful = true;
      } else {
        this.configSaved = true;
      }

      return response;
    },

    getSelectionOfDaysArray() {
      let selectionOfDays = this.selectionOfDays;
      if (!Array.isArray(selectionOfDays)) {
        selectionOfDays = [selectionOfDays];
      }
      return selectionOfDays;
    },

    getObjectToSend() {
      const timeObject = {
        selectionOfDays: this.getSelectionOfDaysArray(),
        syncTime: this.syncTime,
        everyHour: this.everyHour,
      };
      if (this.title === this.$t('configObjectsSync')) {
        return {
          configSyncJobDefinition: timeObject
        };
      } else {
        return {
          timeEntrySyncJobDefinition: timeObject
        };
      }
    },

    dispatchCurrentConnectionBox(timeObject) {
      const connectionBoxes = this.$store.state.userConnectionBoxes;
      for (let pos = 0; pos < connectionBoxes.length; ++pos) {
        if (connectionBoxes[pos].connectionBoxId === this.connectionBoxId) {
          if (this.title === this.$t('configObjectsSync')) {
            connectionBoxes[pos].schedule.configObjects = timeObject;
          } else if (this.title === this.$t('timeEntriesSync')) {
            connectionBoxes[pos].schedule.timeEntries = timeObject;
          }
          break;
        }
      }
      this.$store.dispatch("changeUserConnectionBoxes", connectionBoxes);
    },

    fetchTimeChoser() {
      const currentConnectionBox = this.getCurrentConnectionBox();
      const timeObject = this.title === this.$t('configObjectsSync') ? currentConnectionBox.schedule.configObjects : currentConnectionBox.schedule.timeEntries;
      let selectionOfDays = timeObject.selectionOfDays;
      if (!this.isSelectMultipleDaysEnabled) {
        if (typeof selectionOfDays === "object") {
          selectionOfDays = Object.values(selectionOfDays);
        }
        if (Array.isArray(selectionOfDays)) {
          selectionOfDays = selectionOfDays[0];
        }
      }
      this.selectionOfDays = selectionOfDays;
      this.lastProcessedElectionOfDays = selectionOfDays;
      this.syncTime = timeObject.syncTime;
      this.lastProcessedSyncTime = timeObject.syncTime;
      this.everyHour = timeObject.everyHour;
      this.lastProcessedEveryHour = timeObject.everyHour;
    },
  },
  mounted() {
    EventBus.$on("connection-box-saved", (oldId, newId) => {
      if (oldId === this.connectionBoxId) {
        this.connectionBoxId = newId;
        this.fetchTimeChoser();
      }
    });
  },
  props: ["title", "connectionBoxId"],
};
</script>

<style scoped></style>
